/* Cards */

.card {
  .card-body {
    padding: $card-padding-y $card-padding-x;
    @media (max-width: 767px) {
      padding: 2rem 2rem;
    }
    + .card-body {
      padding-top: 1rem;
    }
  }
  .card-title {
    color: $card-title-color;
    margin-bottom: 1.2rem;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
    font-size: 0.875rem;
  }

  .card-title-big {
    color: $card-title-big-color;
    margin-bottom: 1.2rem;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
    font-size: 1.2rem;
  }
  .card-subtitle {
    @extend .text-gray;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  // .card-description {
  //   margin-bottom: 0.875rem;
  //   font-weight: $font-weight-medium;
  //   color: $card-description-color;
  // }
  .card-description {
    margin-bottom: 0.875rem;
    font-weight: $font-weight-bold;
    color: $card-description-big-color;
  }

  .card-description-big {
    margin-bottom: 0.875rem;
    font-weight: $font-weight-medium;
    color: $card-description-big-color;
  }
  &.card-outline-success {
    border: 1px solid theme-color("success");
  }
  &.card-outline-primary {
    border: 1px solid theme-color("primary");
  }
  &.card-outline-warning {
    border: 1px solid theme-color("warning");
  }
  &.card-outline-danger {
    border: 1px solid theme-color("danger");
  }
  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(
      rgba(theme-color($color), 0.2),
      theme-color-level($color, 1),
      theme-color-level($color, 3)
    );
  }
}

@each $color, $value in $theme-colors {
  .data-icon-card-#{$color} {
    background: $value;
    color: $white;
    .card-title {
      color: $white;
    }
    .background-icon {
      @extend .d-flex;
      @extend .justify-content-end;
      &::before {
        content: url("../../images/dashboard/shape-4.svg");
        position: absolute;
      }
      i {
        z-index: 1;
        @extend .icon-lg;
        color: $white;
      }
    }
  }
}
